















import { Component, Vue } from "vue-property-decorator";
import accesos_appModule from "@/store/modules/Admin/accesos_app-module";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue")
  }
})
export default class AccesosAppLista extends Vue {
  created() {
    accesos_appModule.getaccesos_apps();
  }

  get accesos() {
    return accesos_appModule.accesos_apps;
  }

  get Columns() {
    var Columnas: ClassColumnDataTable[] = [];
    Columnas.push(
      new ClassColumnDataTable(
        "id_nutricionista",
        "Nutricionista",
        datatypes.number,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "Fecha",
        datatypes.date,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "fecha",
        "Fecha Hora",
        datatypes.datetime,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "accede",
        "Accede",
        datatypes.boolean,
        true,
        datatypes.maxlength
      )
    );
    Columnas.push(
      new ClassColumnDataTable(
        "ip",
        "Ip",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    return Columnas;
  }
}
